<template>
  <div class="container">
    <div class="iframe-module">
      <div
        class="iframe-container"
        :style="{backgroundImage: 'url('+iframe_model+')'}"
      >
        <iframe
          class="iframe-box"
          src="/applet.html"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { initPageStyleFun } from '@/utils/utils_fun'
import Header from '@/components/header/Header.vue'
export default {
  // 允许组件模板递归地调用自身
  name: 'Mobile',
  // 声明一组可用于组件实例中的组件
  components: {
    Header,
  },
  // 一个用于从父组件接收数据的数组或对象
  props: {
  },
  // 该函数返回组件实例的 data 对象
  data () {
    return {
      iframe_model: require('../../assets/images/mobile-model2.png'),
    }
  },
  // 计算属性：
  computed: {

  },
  // 钩子函数--侦听data变动：
  watch: {

  },
  // 在实例创建完成后被立即同步调用
  methods: {

  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {

  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {

  },
  beforeCreate() { // 创建前
    // 初始化页面样式
    // initPageStyleFun();
  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前

  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>

.container {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%,-50%);
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.iframe-module {
  .iframe-container {
    // width: 375px;
    // height: 640px;
    // width: 375px;
    // height: 750px;
    // width: 320px;
    // height: 640px;
    /* 750px width (真实模拟手机的大小) */
    // width: 421px;
    // height: 842px;
    /* 320px width (模型的大小) 366px = 模型大小(320) + 内边距(46)*/
    // width: 366px;
    // height: 732px;
    width: 312px;
    height: 624px;
    box-sizing: border-box;
    padding: 19px 23px;
    // margin: 0 auto;
    margin-top: -8%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0px 0px;
    .iframe-box {
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 20px;
      overflow: hidden;
      background: #fff;
    }
  }
}
</style>
